/** @format */

.loginpage-fullscreen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginpage-main-logo {
  margin-bottom: 40px;
}

.loginpage-field-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loginpage-field-container {
  display: flex;
  flex-direction: column;
  color: var(--color-neutral-dark-700);
}

.loginpage-field-container .btn {
  width: 96%;
}

.loginpage-field-input {
  border-radius: 40px;
  padding: 10px 20px;
  margin-top: 10px;
  margin-right: 40px;
  border: 1px solid var(--color-neutral-dark-600);
}

.loginpage-field-input:focus {
  border: 1px solid var(--color-primary-pink) !important;
}

.loginpage-field-spacing {
  margin-bottom: 40px;
}
.loginpage-validation-error-label {
  color: #fb264c;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 3px;
}

.form-item input {
  width: 96%;
}
.form-item svg {
  margin-left: -85px;
  color: var(--color-primary-pink);
}
