/** 
 * @format
 */

.modal-content {
  width: 50%;
  padding: 25px 0;
  margin: 27vh auto;
}

.modal-dialog {
  width: 53%;
  height: 48%;
  max-width: 766px !important;
  max-height: auto;
  margin: 15% auto;
}

.message-modal-header {
  text-align: center;
  font-size: 22px;
  font-family: var(--font-din);
  width: 100% !important;
}

.modal-header {
  border-bottom: none;
}

.modal-body {
  width: 85%;
  margin: auto;
  text-align: justify;
  padding: unset;
}

.modal-footer {
  flex-direction: row;
  border-top: none;
  margin-top: 25px;
}

.modal-description {
  margin: auto;
  text-align: center;
  width: 100%;
}

.message-modal .modal-header .close {
  width: 50px;
  outline: none;
}

.message-modal .modal-footer {
  width: 100%;
  justify-content: center;
}

.company-choice-modal-content {
  flex: 1;
}

.company-choice-modal-submit-button {
  margin: 0 auto;
  text-align: center !important;
}

@media (max-width: 1299px) {
  .modal-dialog {
    max-width: unset !important;
    width: 85%;
    min-width: 300px;
    height: 48%;
    max-height: auto;
    margin: 15% auto;
  }
}

@media (max-width: 949px) {
  .modal-content {
    width: 70%;
  }
}

@media (max-width: 649px) {
  .modal-content {
    width: 100%;
  }
}

@media (max-width: 919px) {
  .modal-dialog {
    max-width: unset !important;
    width: 100%;
    min-width: 200px;
    height: 75%;
    max-height: auto;
    margin: 15% auto;
  }
  .modal-content {
    width: 100%;
    border-radius: 0;
  }
}
