/** @format */

.image-homepage {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

.image-homepage-1 {
  background-image: var(--image-homepage-1);
  height: 524px;
}

.image-homepage-2 {
  background-image: var(--image-homepage-2);
  max-width: 300px;
  height: 340px;
}

.image-homepage-3 {
  background-image: var(--image-homepage-3);
  margin: auto;
  width: 213px;
  height: 390px;
}

.image-partners-monochrome {
  background-image: var(--image-partners-monochrome);
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 1440px;
  height: 740px;
  margin: auto;
}

.image-partners-monochrome-mobile {
  background-image: var(--image-partners-monochrome-mobile);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 100%;
  height: auto;
  margin: auto;
}
