/** @format */

.allbrokers-container {
  margin: 40px;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.allbrokers-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  text-transform: uppercase;
}

.allbrokers-table {
  display: block;
  overflow: auto;
}

.allbrokers-table thead tr th,
.allbrokers-table tbody tr td {
  height: 70px;
  vertical-align: middle;
}

.allbrokers-table tbody {
  border-top: 2px solid var(--color-neutral-dark-500) !important;
}

.allbrokers-table tbody tr td:nth-child(1),
.allbrokers-table tbody tr td:nth-child(8),
.allbrokers-table tbody tr td:nth-child(9) {
  text-align: center;
}

.allbrokers-table tbody tr td:nth-child(2) {
  width: 1%;
  white-space: nowrap;
}

.allbrokers-table thead tr th:nth-child(6) {
  padding-left: 150px;
  padding-right: 150px;
}

.allbrokers-table thead tr th:nth-child(8),
.allbrokers-table thead tr th:nth-child(9) {
  width: 1%;
  min-width: 135px;
}

.allbrokers-table-status-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 10px 10px;
  border-radius: 40px;
}

.allbrokers-table-status-container p {
  color: white;
  margin-left: 5px;
}

.allbrokers-table-status-container-green {
  background-color: var(--color-primary-green);
}

.allbrokers-table-status-container-red {
  background-color: var(--color-primary-red);
}

.allbrokers-search-field input {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-left: none !important;
  border-radius: 40px;
  width: 100%;
}

.allbrokers-search-field .input-group .input-group-text {
  border-radius: 40px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
