/** @format */

.icon-linkedin-light {
  background-image: var(--icon-linkedin-light);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 22px;
  height: 20px;
}

.icon-facebook-light {
  background-image: var(--icon-facebook-light);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 22px;
  height: 20px;
}

.icon-instagram-light {
  background-image: var(--icon-instagram-light);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 22px;
  height: 20px;
}

.icon-twitter-light {
  background-image: var(--icon-twitter-light);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 22px;
  height: 20px;
}

.icon-benefits-item-1 {
  background-image: var(--icon-benefits-item-1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 130px;
  height: 120px;
  margin: auto;
}

.icon-benefits-item-2 {
  background-image: var(--icon-benefits-item-2);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 130px;
  height: 120px;
  margin: auto;
}

.icon-benefits-item-3 {
  background-image: var(--icon-benefits-item-3);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 130px;
  height: 120px;
  margin: auto;
}

.icon-benefits-item-4 {
  background-image: var(--icon-benefits-item-4);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 130px;
  height: 120px;
  margin: auto;
}

.icon-what-we-offer-1 {
  background-image: var(--icon-what-we-offer-1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  width: 157px;
  height: 157px;
  position: relative;
  top: 130px;
  left: 14px;
}

.icon-what-we-offer-2 {
  background-image: var(--icon-what-we-offer-2);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  width: 157px;
  height: 157px;
  position: relative;
  top: 130px;
  left: 14px;
}

.icon-what-we-offer-3 {
  background-image: var(--icon-what-we-offer-3);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  width: 157px;
  height: 157px;
  position: relative;
  top: 130px;
  left: 14px;
}

@media (max-width: 919px) {
  .icon-what-we-offer-1 {
    background-image: var(--icon-what-we-offer-1-mobile);
    width: 60px;
    height: 60px;
    left: 115px;
    top: 0;
  }

  .icon-what-we-offer-2 {
    background-image: var(--icon-what-we-offer-2-mobile);
    width: 60px;
    height: 60px;
    left: 115px;
    top: 0;
  }

  .icon-what-we-offer-3 {
    background-image: var(--icon-what-we-offer-3-mobile);
    width: 60px;
    height: 60px;
    left: 115px;
    top: 0;
  }
}
