/** @format */

.header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  padding: 0 5%;
  max-width: 1440px;
  width: auto;
  height: var(--height-header);
  border-radius: 0px;
  margin: auto;
}

.header-left-container {
  flex: 1;
  height: inherit;
  display: flex;
}

.header-left-container .logo {
  flex: 1;
}

.header-middle-container {
  flex: 1;
  height: inherit;
}

.header-right-container {
  flex: 1;
  height: inherit;
  display: flex;
  justify-items: right;
  padding-right: 5%;
}

.header-button-item {
  display: flex;
  align-self: center;
  height: 47px;
}

.header-button-item:first-child {
  flex: 1;
  justify-content: right;
  margin-right: 15px;
}

.header-button-item:last-child {
  flex: 1;
  justify-self: flex-start;
}

.header-right-container .btn {
  min-width: 150px;
}

/** mobile header styles **/

.mobile-header-right-right-block {
  position: absolute;
  top: 0;
  right: 0;
}

.mobile-header-right-right-block .menu-wrapper {
  width: 100vw;
  height: 261px;
}

.mobile-header-right-button-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: auto;
  margin-top: 99px;
}

.mobile-header-button-item:first-child {
  display: flex;
  justify-content: space-evenly;
  flex: 1;
  align-items: self-end;
  width: inherit;
}

.mobile-header-button-item:last-child {
  margin-top: 15px;
  display: flex;
  justify-content: space-evenly;
  flex: 1;
  align-items: self-start;
  width: inherit;
}

.mobile-header-button-item:first-child .btn,
.mobile-header-button-item:last-child .btn {
  margin: auto 25px;
  width: 196px;
}

.dropdown-toggle::after {
  display: none !important;
}

@media screen and (max-width: 919px) {
  .header-button-item {
    display: none;
  }
  .hamburger-menu {
    display: block;
  }
}
