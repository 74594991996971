/** @format */

.table-data-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.modal-content {
  border-radius: 15px;
  width: 65% !important;
  margin: none !important;
  padding: 25px 25px !important;
}

.modal-body {
  margin: 0rem 1rem !important;
}

.modal-button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal-fullwidth-button {
  width: 100% !important;
}

.modal-second-button {
  margin-top: 10px;
}

.modal-footer {
  justify-content: center !important;
}

.pink-text {
  color: var(--color-primary-pink);
}

@media (min-width: 920px) and (max-width: 989px) {
  .session-expiry-alert {
    padding-right: 120px;
  }
}

@media (max-width: 919px) {
  .session-expiry-alert {
    padding-right: initial;
  }
  .alert.alert-warning {
    margin: 15px -15px 15px;
    border-radius: 0;
  }
  .profile-resubscription-flow-container .content {
    width: 100% !important;
  }
}
