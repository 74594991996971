/** @format */

.dashboard-main-container {
  min-height: 100vh;
  max-width: 100vw;
}

.dashboard-body-section-1 .nav-link {
  color: var(--color-neutral-dark-600);
}

.dashboard-body-section-1 .nav-link.active {
  border-left-color: var(--color-neutral-neutral-100);
  border-right-color: var(--color-neutral-neutral-100);
  border-top-color: var(--color-neutral-neutral-100);
  color: var(--color-neutral-dark-900);
  font-weight: 500;
  border-bottom: 3px solid var(--color-neutral-dark-900) !important;
}

.dashboard-body-section-1 .nav-link:hover {
  border-left-color: var(--color-neutral-neutral-100);
  border-right-color: var(--color-neutral-neutral-100);
  border-top-color: var(--color-neutral-neutral-100);
  color: var(--color-neutral-dark-700);
  border-bottom: 3px solid var(--color-neutral-dark-700) !important;
}

.dashboard-nav-container {
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  height: 100vh;
  max-height: 100vh;
  padding: 0px 40px;
  border-right: 1px solid var(--color-neutral-neutral-300);
}

.dashboard-body-section-1 .nav-tabs {
  border-bottom: none;
}

.dashboard-header-image {
  width: 85%;
  margin: 40px 0px;
}

.dashboard-nav-profile-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}

.dashboard-body-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100vh;
  min-height: 100vh;
  max-width: 100vw;
  margin-left: 280px;
  overflow-x: auto;
}

.dashboard-body-section-1 {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px;
}

.dashboard-body-section-2 {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-top: 1px solid var(--color-neutral-neutral-300);
}

.dashboard-body-section-2-inner {
  display: flex;
  flex-direction: row;
}

.dashboard-preonboarding-table {
  display: block;
  overflow: auto;
}

.dashboard-preonboarding-table thead tr th,
.dashboard-preonboarding-table tbody tr td {
  height: 70px;
  vertical-align: middle;
}

.dashboard-preonboarding-table tbody tr td:nth-child(3) {
  text-align: center;
}

.dashboard-preonboarding-table tbody tr td:nth-child(1) {
  text-align: center;
}

.dashboard-preonboarding-table tbody {
  border-top: 2px solid var(--color-neutral-dark-500) !important;
}

.dashboard-inner-section-1 {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 60%;
  padding: 40px;
  border-right: 1px solid var(--color-neutral-neutral-300);
}

.dashboard-inner-section-2 {
  flex: 1;
  padding: 40px;
}

.dashboard-field-container {
  display: flex;
  flex-direction: column;
  color: var(--color-neutral-dark-700);
}

.dashboard-field-input {
  border-radius: 40px;
  padding: 10px 20px;
  margin-top: 10px;
  margin-right: 40px;
  border: 1px solid var(--color-neutral-dark-600);
}

.dashboard-field-input:focus {
  border: 1px solid var(--color-primary-pink) !important;
}

.dashboard-table-buttons {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.dashboard-nav-buttons {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin: 0px -40px;
}

.dashboard-nav-active-img {
  margin-left: 35px;
}

.dashboard-nav-inactive-img {
  margin-left: 40px;
}

.dashboard-nav-active-bar::before {
  width: 5px;
  height: 19px;
  background-color: var(--color-primary-pink);
  border-radius: 10px;
  content: '';
}

.dashboard-table-button-spacing {
  margin-right: 20px;
}

.dashboard-pagination-text {
  color: var(--color-neutral-dark-600);
  font-size: 14px;
  font-weight: 400;
}

.dashboard-pagination-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dashboard-pagination-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dashboard-pagination-numbers-container {
  display: flex;
  flex-direction: row;
  border: 1px solid gray;
  margin: 0px 10px;
  border-radius: 40px;
}

.dashboard-pagination-numbers-container div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 25px;
  width: 25px;
}

.dashboard-pagination-number-active {
  background-color: black;
  color: white;
  border-radius: 50%;
}

.dashboard-nav-menu {
  margin-top: 20px;
}

.dashboard-nav-menu div {
  margin-bottom: 5px;
}

.dashboard-logout-container {
  display: flex;
  align-items: center;
  min-height: 100px;
  border-top: 1px solid var(--color-neutral-neutral-300);
  margin: 0px -40px;
  padding: 0px 40px;
  cursor: pointer;
}

.dashboard-logout-container img {
  margin-right: 10px;
}

.dashboard-profile-text {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.dashboard-nav-menu div img {
  margin-right: 10px;
}

.dashboard-main-heading {
  padding-bottom: 20px;
  text-transform: uppercase;
}

.dashboard-field-container input {
  margin-right: 0px;
  margin-bottom: 20px;
}

.dashboard-nav-buttons .new-leads-count {
  background-color: var(--color-primary-pink);
  color: white;
  width: 45px;
  margin-left: 10px;
  text-align: center;
  border-radius: 10px;
}
